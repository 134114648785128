.title {
	font-size: 24px;
	margin-bottom: 16px;
}

.input,
.label {
	width: 100%;
}

/* .input, */
.inputPassword,
.inputField {
	padding: 2% 3%;
}

.inputLabel,
.inputButton {
	font-size: 16px;
}

.inputButton {
	width: 100%;
	height: 100%;
	padding: 3% 0;
	margin: 10% 0;
}

.input {
	margin: 40px 0 0;
}

.forgotPassword {
	font-size: 14px;
}

.inputCheckbox {
	margin-top: 3%;
}

.inputCheckbox input {
	width: 3%;
	margin-right: 3%;
}

.labelPass {
	margin-top: 3%;
}

.cursorPointer {
	cursor: pointer;
}

@media (max-width: 600px) {
}

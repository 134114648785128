.bordered {
	box-shadow: 0 8px 6px -6px black;
}
.navbar {
	height: 10vh;
	width: 85%;
	margin: 0 auto;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.leftHeader {
	display: inline-flex;
}

.version {
	font-size: 10px;
	align-self: flex-end;
	font-style: italic;
	font-weight: 600;
}

.icon-img {
	height: 35%;
}

.uid {
	font-size: 14px;
}
